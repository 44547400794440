<template>
  <v-main>
    <v-container>
      <v-card>
        <v-card-title class="text-h4 pl-12 pt-8">Corporate Identity</v-card-title>
        <v-card-text class="black--text pa-12">

          <div class="text-h5">Schriftarten</div>
          <div class="text-h6 mt-2">Roboto</div>
          <div class="mt-2">
            <v-btn class="mr-3" href="https://roy-spitzner.de/static-downloads/ci/Roboto-Regular.ttf">
              <v-icon left small>mdi-download</v-icon>
              <span>Regular</span>
            </v-btn>
            <v-btn href="https://roy-spitzner.de/static-downloads/ci/Roboto.zip">
              <v-icon left small>mdi-download</v-icon>
              <span>Alle Formate</span>
            </v-btn>
          </div>

          <div class="mt-4" style='font-family: "Caveat", "Ink Free", cursive !important; font-size: 20pt'>Caveat</div>
          <div class="mt-3">
            <v-btn class="mr-3" href="https://roy-spitzner.de/static-downloads/ci/Caveat-VariableFont.ttf">
              <v-icon left small>mdi-download</v-icon>
              <span>All-In-One</span>
            </v-btn>
            <v-btn href="https://roy-spitzner.de/static-downloads/ci/Caveat.zip">
              <v-icon left small>mdi-download</v-icon>
              <span>Einzelne Dateien</span>
            </v-btn>
          </div>

          <div class="text-h5 mt-12">Farben</div>
          <div class="text-h6 mt-2">Einführung</div>
          <div class="d-flex mt-1 align-center" style="font-size: 16pt">
            <v-btn color="#3c9b9c" width="150"></v-btn>
            <div class="ml-4">#3c9b9c</div>
            <div class="ml-4">rgb(60, 155, 156)</div>
          </div>

          <div class="text-h6 mt-2">Mein Selbst</div>
          <div class="d-flex mt-1 align-center" style="font-size: 16pt">
            <v-btn color="#AB4642" width="150"></v-btn>
            <div class="ml-4">#ab4642</div>
            <div class="ml-4">rgb(171, 70, 66)</div>
          </div>

          <div class="text-h6 mt-2">Mein Umfeld</div>
          <div class="d-flex mt-1 align-center" style="font-size: 16pt">
            <v-btn color="#DC9656" width="150"></v-btn>
            <div class="ml-4">#dc9656</div>
            <div class="ml-4">rgb(220, 150, 86)</div>
          </div>

          <div class="text-h6 mt-2">Meine Karriere</div>
          <div class="d-flex mt-1 align-center" style="font-size: 16pt">
            <v-btn color="#76b82a" width="150"></v-btn>
            <div class="ml-4">#76b82a</div>
            <div class="ml-4">rgb(118, 184, 42)</div>
          </div>

          <div class="text-h6 mt-2">Meine Finanzen</div>
          <div class="d-flex mt-1 align-center" style="font-size: 16pt">
            <v-btn color="#044380" width="150"></v-btn>
            <div class="ml-4">#044380</div>
            <div class="ml-4">rgb(4, 67, 128)</div>
          </div>

          <div class="text-h6 mt-2">Meine Gesundheit</div>
          <div class="d-flex mt-1 align-center" style="font-size: 16pt">
            <v-btn color="#a26f97" width="150"></v-btn>
            <div class="ml-4">#a26f97</div>
            <div class="ml-4">rgb(162, 111, 151)</div>
          </div>

          <div class="text-h6 mt-2">Vordergrund</div>
          <div class="d-flex mt-1 align-center" style="font-size: 16pt">
            <v-btn color="white" width="150"></v-btn>
            <div class="ml-4">#ffffff</div>
            <div class="ml-4">rgb(255, 255, 255)</div>
          </div>

          <div class="text-h6 mt-2">Hintergrund</div>
          <div class="d-flex mt-1 align-center" style="font-size: 16pt">
            <v-btn color="#f8f8f8" width="150"></v-btn>
            <div class="ml-4">#f8f8f8</div>
            <div class="ml-4">rgb(248, 248, 248)</div>
          </div>

          <div class="text-h5 mt-12">Logos & Bilder</div>
          <div class="mt-3">
            <v-btn class="mr-3" href="https://roy-spitzner.de/static-downloads/ci/images.zip">
              <v-icon left small>mdi-download</v-icon>
              <span>Download</span>
            </v-btn>
          </div>

        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "CorporateIdentity"
};
</script>

<style scoped>
@media (min-width: 1264px) {
  .container {
    max-width: 1185px;
  }
}
</style>